import { Layout } from "antd";

import Logo from "../Logo/yip-yap-logo-png.png";

const { Content } = Layout;
const Headshot = ({ src }) => {
  return (
    <Layout className="headshot">
      <Content>
        <img src={src || Logo} alt="headshot" />
      </Content>
    </Layout>
  );
};

export default Headshot;
