import { MenuOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import { Header } from "antd/lib/layout/layout";

import { Link } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../Context/UserContext";
import Logo from "../Logo/Logo";

const NavBar = ({ toggleSideBar }) => {
  const { user } = useContext(UserContext);

  return (
    <Header className="navbar">
      <div className="nav-item-container">
        <MenuOutlined onClick={toggleSideBar} style={{ fontSize: "2em" }} />
        <Logo size="small" />
        <Link to={`/profile/${user ? user.username : "Yip-Yap-Team"}`}>
          <Avatar
            style={{
              border: "2px solid #fff",
              backgroundColor: "transparent",
            }}
            icon={<UserOutlined />}
          />
        </Link>
      </div>
    </Header>
  );
};

export default NavBar;
